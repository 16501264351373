import {
  AdviseurMenu,
  AuthContext,
  FormFirstFocus,
  InvalidAdviesboxResultErrorPage,
  Notificaties2Menu,
  NotificatiesMenu,
  PlatformFoutenSamenvatting,
  SupportMenu,
  SupportRemoteAssistanceModal,
  SupportTicketModalAjax,
  useFeature,
  useRequestInit,
  ZoekschermBody,
  ZoekschermHeader,
  ZoekschermHeaderColumn,
  ZoekschermHeading,
  ZoekschermItem,
  ZoekschermProductSelect,
  ZoekschermTopNavbar,
} from "adviesbox-shared";
import { Form, FormikContextType } from "formik";
import React, { Dispatch, ReactElement, useContext, useState } from "react";
import { Debug } from "../shared/components/formik/Debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { asyncLicentiebeheerZoekSchermSideEffects } from "./determine-zoekscherm-async-side-effects";
import { zoekschermSchema, ZoekschermState } from "./infra";
import NewLogo from "../assets/new-brand-logo.svg";
import "./zoekscherm.scss";
import Zoeken from "./zoeken/zoeken";
import classes from "./zoekscherm.module.scss";

const Zoekscherm = ({
  childErrorSetter,
}: FormikContextType<ZoekschermState> & {
  childErrorSetter: Dispatch<number | null>;
}): ReactElement => {
  const [showMeldingmaken, setShowMeldingMaken] = useState(false);
  const [showRemoteAssistance, setShowRemoteAssistance] = useState(false);
  const [
    showInvalidAdviesboxResultErrorPage,
    setShowInvalidAdviesboxResultErrorPage,
  ] = useState(false);
  const featureNotificaties2 = useFeature("FeatureNotificaties2");

  const { params } = useRequestInit();
  const { user } = useContext(AuthContext);

  /*istanbul ignore next*/
  if (showInvalidAdviesboxResultErrorPage) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <FormFirstFocus>
      <Form>
        <ZoekschermHeader>
          <ZoekschermTopNavbar>
            <ZoekschermHeaderColumn alignRight={false}>
              <ZoekschermProductSelect currentApp="LIC" logo={NewLogo} />
            </ZoekschermHeaderColumn>
            <ZoekschermHeaderColumn alignRight={true}>
              <ZoekschermItem>
                <SupportMenu
                  showMeldingMaken={
                    /*istanbul ignore next*/ () => setShowMeldingMaken(true)
                  }
                  showRemoteAssistance={
                    /* istanbul ignore next */ () =>
                      setShowRemoteAssistance(true)
                  }
                  classes={{
                    iconblack: classes.iconblack,
                    iconpadding: classes.iconpadding,
                  }}
                />
              </ZoekschermItem>
              <ZoekschermItem>
                {!featureNotificaties2 && (
                  <div data-testid="NotificatiesMenu">
                    <NotificatiesMenu />
                  </div>
                )}
                {featureNotificaties2 && (
                  <div data-testid="NewNotificatiesMenu">
                    <Notificaties2Menu />
                  </div>
                )}
              </ZoekschermItem>
              <ZoekschermItem>
                <AdviseurMenu />
              </ZoekschermItem>
            </ZoekschermHeaderColumn>
          </ZoekschermTopNavbar>
          <PlatformFoutenSamenvatting />
          <ISWSideEffects<ZoekschermState>
            async={asyncLicentiebeheerZoekSchermSideEffects()}
          />
          <ZoekschermHeading heading="Adviesbox Contract" />
          <Zoeken childErrorSetter={childErrorSetter} />
        </ZoekschermHeader>
        <ZoekschermBody>
          <div className="container">
            <Debug />
          </div>
        </ZoekschermBody>
        {/* istanbul ignore next */ showMeldingmaken && user && (
          <SupportTicketModalAjax
            adviesdossierId={params.adviesdossier ?? null}
            user={user}
            closeModal={() => setShowMeldingMaken(false)}
            showInvalidResultErrorPage={() =>
              setShowInvalidAdviesboxResultErrorPage(true)
            }
            vestigingId={params.vestiging}
          />
        )}
        {/* istanbul ignore next */ showRemoteAssistance && (
          <SupportRemoteAssistanceModal
            closeModal={() => setShowRemoteAssistance(false)}
          />
        )}
      </Form>
    </FormFirstFocus>
  );
};

Zoekscherm.displayName = "Zoekscherm";
export default withAdviesboxFormik<
  ZoekschermState & { childErrorSetter: Dispatch<number | null> },
  ZoekschermState
>({
  mapPropsToValues: (val): ZoekschermState => val,
  validationSchema: zoekschermSchema,
})(Zoekscherm);
